<template>
  <KnowledgeExperience
    title='onboarding.investmentSurvey.views.overview.title'
    subtitle='onboarding.investmentSurvey.views.overview.intro'
  />
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'InvestmentSurveyOverview',
  components: {
    KnowledgeExperience: lazyLoad('components/Onboarding/KnowledgeExperience'),
  },
};
</script>
